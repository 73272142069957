<template>
  <div class="p-10 mx-2 bg-primary-20 text-subheaderText rounded-md shadow flex flex-col md:flex-row items-start text-left">
    <img
    class="w-32 h-32 mx-auto md:mx-0"
      :src="require('@/assets/images/' + latestReviews.image)"
      :alt="latestReviews.image"
    />
    <div class="md:ml-10">
      <h5 class="text-center font-bold">{{ latestReviews.name }}</h5>
      <h5 class="my-2 font-bold">{{ latestReviews.date }}</h5>
      <p>{{ latestReviews.comment }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["latestReviews"],
};
</script>

<style>
</style>