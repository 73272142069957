<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0625 0H8.9375C6.56713 0 4.29384 0.941627 2.61773 2.61773C0.941627 4.29384 0 6.56713 0 8.9375L0 12.1875C0 14.5579 0.941627 16.8312 2.61773 18.5073C4.29384 20.1834 6.56713 21.125 8.9375 21.125H9.75V25.1875C9.74906 25.3489 9.7962 25.5069 9.88542 25.6414C9.97463 25.7759 10.1019 25.8807 10.2509 25.9426C10.4 26.0045 10.5641 26.0206 10.7223 25.9889C10.8805 25.9571 11.0257 25.879 11.1394 25.7644L15.7706 21.125H17.0625C19.4329 21.125 21.7062 20.1834 23.3823 18.5073C25.0584 16.8312 26 14.5579 26 12.1875V8.9375C26 6.56713 25.0584 4.29384 23.3823 2.61773C21.7062 0.941627 19.4329 0 17.0625 0V0ZM24.375 12.1875C24.375 14.1269 23.6046 15.9869 22.2332 17.3582C20.8619 18.7296 19.0019 19.5 17.0625 19.5H15.4375C15.3306 19.4994 15.2246 19.5199 15.1256 19.5603C15.0266 19.6007 14.9365 19.6603 14.8606 19.7356L11.375 23.2294V20.3125C11.375 20.097 11.2894 19.8903 11.137 19.738C10.9847 19.5856 10.778 19.5 10.5625 19.5H8.9375C6.9981 19.5 5.13814 18.7296 3.76678 17.3582C2.39542 15.9869 1.625 14.1269 1.625 12.1875V8.9375C1.625 6.9981 2.39542 5.13814 3.76678 3.76678C5.13814 2.39542 6.9981 1.625 8.9375 1.625H17.0625C19.0019 1.625 20.8619 2.39542 22.2332 3.76678C23.6046 5.13814 24.375 6.9981 24.375 8.9375V12.1875Z"
      :fill="iconColor"
    />
    <path d="M21.125 9.75H4.875V11.375H21.125V9.75Z" :fill="iconColor" />
    <path d="M19.5 5.6875H6.5V7.3125H19.5V5.6875Z" :fill="iconColor" />
    <path d="M19.5 13.8125H6.5V15.4375H19.5V13.8125Z" :fill="iconColor" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#ff6961",
    },
    bgColor: {
      type: String,
      default: "#ff69612e",
    },
  },
};
</script>