<template>
  <div class="image-slider my-10">
    <div class="main-image">
      <img
        class="rounded-md"
        :src="currentImage"
        alt="Main Slide"
        style="width: 500px; height: 250px"
      />
    </div>
    <div class="preview-images">
      <div
        class="preview-slider"
        :style="{ transform: `translateX(-${sliderPosition}px)` }"
      >
        <div
          v-for="(image, index) in visibleImages"
          :key="index"
          class="preview-image rounded-md"
          :class="{ active: index === activeIndex }"
          @click="changeImage(index)"
        >
          <img class="rounded-md" :src="image" alt="Preview Slide" style="width: 500px; height: 100px"/>
        </div>
      </div>
    </div>
    <div class="w-full flex items-center justify-between">
      <ArrowNextVue
        class="cursor-pointer"
        style="rotate: 180deg"
        @click.native="scrollSlider('left')"
      />
      <ArrowNextVue
        class="cursor-pointer"
        @click.native="scrollSlider('right')"
      />
    </div>
  </div>
</template>

<script>
import ArrowNextVue from "../icons/ArrowNext.vue";

export default {
  props: {
    images: Array,
  },
  components: {
    ArrowNextVue,
  },
  data() {
    return {
      activeIndex: 0,
      sliderPosition: 0,
      previewImageWidth: 100, // Adjust this value based on your image width
      imagesPerSet: 3,
    };
  },
  computed: {
    currentImage() {
      return this.images[this.activeIndex];
    },
    totalSets() {
      return Math.ceil(this.images.length / this.imagesPerSet);
    },
    visibleImages() {
      const startIndex =
        this.activeIndex - (this.activeIndex % this.imagesPerSet);
      return this.images.slice(startIndex, startIndex + this.imagesPerSet);
    },
  },
  methods: {
    changeImage(index) {
      this.activeIndex = index;
    },
    scrollSlider(direction) {
      const step = this.previewImageWidth * this.imagesPerSet;
      if (direction === "left") {
        this.activeIndex = Math.max(this.activeIndex - this.imagesPerSet, 0);
      } else if (direction === "right") {
        this.activeIndex = Math.min(
          this.activeIndex + this.imagesPerSet,
          this.images.length - 1
        );
      }
    },
  },
};
</script>

<style>
.image-slider {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image img {
  max-width: 100%;
  height: auto;
  /* border: 1px solid #ccc; */
  /* padding: 10px; */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
  transition: transform 0.3s ease;
}

.preview-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  overflow: hidden;
}

.preview-slider {
  display: flex;
  transition: transform 0.3s ease;
}

.preview-image {
  cursor: pointer;
  margin: 0 5px;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  width: 100px; /* Add this line with the desired width */
}

.preview-image.active {
  opacity: 1;
}

.preview-image img {
  width: 100%;
  height: auto;
  /* border: 1px solid #ccc; */
  /* padding: 5px; */
}
.prev-button,
.next-button {
  margin-top: 10px;
}
</style>
